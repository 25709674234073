
var load_in_modal = (function () {
  function bindLinks(){
    $('a.preview-response-in-modal').on('click', async function (event)  {
      event.preventDefault();
      const url = event.target.href
      const patchUrl = event.target.dataset.patchUrl
      const loading = `<div role="status">
          <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
      </div>`
      const modal = $('#modal')

      modal.find('.modal-content').html(loading)
      modal.addClass('is-visible')

      var content = ''
      const block =  `<div class="mb-4"><h3 class="mb-2 font-medium"><div class="inline-block py-1 px-2 bg-blue-50 text-blue-500 rounded mx-2">_NUM_</div>_QUE_</h3><p class="border-b border-slate-200 text-sm text-gray-500">_ANS_</p></div>`

      var response = await fetch('/admin/qualtrics/api/key', {})
      var api_token = await response.text();

      const surveyRegex = /surveys\/(.+)\/responses/
      const surveyId = url.match(surveyRegex)[1];
      const surveyUrl = `https://ca1.qualtrics.com/API/v3/surveys/${surveyId}`
      const directUrl = `https://sonyplaystationta.yul1.qualtrics.com/responses/#/surveys/${surveyId}`

      response = await fetch(surveyUrl, {
        cache: 'force-cache',
        headers: {
          'Content-Type': 'application/json',
          'X-API-TOKEN': api_token
        },
      });
      var survey = await response.json();

      response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'X-API-TOKEN': api_token
        },
      });
      var surveyResponse = await response.json();
      const displayed = surveyResponse.result.displayedFields.map(function (el) { return el.replace(/_.+/, '') })

      for (const [questionId, question] of Object.entries(survey.result.questions)) {
        if (!displayed.includes(questionId)) continue
        content = content + block.replace(/_NUM_/g, question.questionName).replace(/_QUE_/g, question.questionText)
        if (question.subQuestions) {
          var answer = ''
          for (const [subQuestionId, subQuestion] of Object.entries(question.subQuestions)) {
            const subAnswer = surveyResponse.result.labels[questionId + '_' + subQuestionId]
            if (typeof(subAnswer) !== 'undefined'){
              var answer = `${answer}${subQuestion.choiceText}: ${subAnswer}<br/>`
            }
          }
        } else {
          // simple response
          var answer = surveyResponse.result.labels[questionId];

          if (question.choices) {
            const choices = Object.entries(question.choices)

            const choice = choices.find(el => el[1]['recode'] == surveyResponse.result.values[questionId])
            if (choice && choice[1]['textEntry']) { // Other + specify
              answer = surveyResponse.result.values[`${questionId}_${choice[0]}_TEXT`]
            }

            if (question.questionType.type == 'TE' && question.questionType.selector == 'FORM') {
              answer = ''
              const mapped = choices.map(obj => {
                const label = obj[1]['choiceText']
                const value = surveyResponse.result.values[`${questionId}_${obj[0]}`]
                if (typeof (value) != 'undefined'){
                  return `${label}: ${value}`
                }
              });
              answer = mapped.filter(el => el != undefined).join('<br/>')
            }

            if (question.questionType.type == 'RO') { // ranked order
              const mapped = choices.reduce((acc, obj) => {
                const key = `${questionId}_${obj[1]['recode']}`;
                acc[key] = obj[1]['choiceText'];
                return acc;
              }, {});
              const ranked = {}
              for (const [key, value] of Object.entries(mapped)) {
                ranked[surveyResponse.result.values[key]] = value;
              }
              answer = Object.values(ranked).join(', ')
            }
          }
        }
        // sub for all cases
        if (typeof answer !== 'undefined') {
          content = content.replace(/_ANS_/g, `${answer}`)
        } else {
          content = content.replace(/_ANS_/g, '')
        }
        answer = ''
      }
      content = content.replace(/_(ANS|NUM|QUE)_/g, '')

      const layout = `<section class=""><div class="container px-4 mx-auto">
        <div class="p-6 bg-white rounded shadow w-full">
          <div class="flex justify-between items-center mb-6">
            <div class="flex justify-between items-center">
              <span class="inline-block py-1 px-2 bg-blue-50 text-blue-500 rounded mx-2"><a href='${directUrl}' target='_new'>${surveyResponse.result.values.recipientFirstName} ${surveyResponse.result.values.recipientLastName}</a></span>
              <span class="inline-block py-1 px-2 bg-blue-50 text-blue-500 rounded mx-2">${surveyResponse.result.values.recipientEmail}</span>
            </div>
            <div class="flex justify-between items-center">
              <a class="action-button action-pass inline-block w-full md:w-auto px-2 py-1 rounded text-sm mx-1" data-action="pass">PASS</a>
              <a class="action-button action-stash inline-block w-full md:w-auto px-2 py-1 rounded text-sm mx-1" data-action="stash">REST</a>
              <a class="action-button action-reject inline-block w-full md:w-auto px-2 py-1 rounded text-sm mx-1" data-action="reject">FAIL</a>
            </div>
          </div>
          ${content}
        </div>
        </div>
      </section>`

      modal.find('.modal-content').html(layout)

      $('.action-button').on('click', async function (event) {
        event.preventDefault()

        const action = $(this).data('action')
        const csrfToken = $('meta[name="csrf-token"]').attr('content')

        const response = await fetch(patchUrl, {
          method: 'PATCH', credentials: 'same-origin',
          headers: {
            'X-CSRF-Token': csrfToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ action: action })
        })

        if (response.ok) {
          toastr.success(`${action} request successful`)
          $('#modal').removeClass('is-visible')
          window.location.reload()
        } else {
          toastr.error(`${action} request failed`)
        }
      })
    })

    $('.close-modal').on('click', function() {
      $('#modal').removeClass('is-visible')
    })

    $(document).on('keyup', function(e) {
      if (e.key == "Escape")
        $('#modal').removeClass('is-visible')
    })
  }

  return {
    init: function () {
      bindLinks();
    }
  };
})(this);

document.addEventListener('turbolinks:load', load_in_modal.init);